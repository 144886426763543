import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes,RouterModule } from '@angular/router'; 
import { AuthModule } from '../auth/auth.module';
//import { ServicesModule } from '../pages/services/services.module';
import { AuthGuard } from '../_guards/auth.guard';

const routes: Routes = [   
    {path: 'auth', loadChildren: '../auth/auth.module#AuthModule'},
   
    {path: 'login', loadChildren: '../pages/login/login.module#LoginModule'},
    //{path: 'services', loadChildren: '../pages/login/login.module#LoginModule'},
    // {path: 'editor', loadChildren: '../editor/editor.module#EditorModule'},

    {path: '**', redirectTo: 'auth/dashboard'},
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class LazyLoadModule { }
