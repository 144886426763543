import {map,finalize} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Http, Response, Headers,RequestOptions} from '@angular/http';
import { DomSanitizer, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { CookieManagerService } from './../cookie.service';

import { environment } from '../../../../environments/environment';

import {LoaderService} from './../loader.services';

@Injectable()
export class wbFotoServices {
	private headers = new Headers();
	constructor(private http: Http,
				private cookie:CookieManagerService,
				private loaderService:LoaderService){
    }
    
    private showLoader(id): void {
      this.loaderService.show(id);
  	}

  	private onEnd(id): void {
      this.loaderService.hide(id);
  	}


   registrarFoto(data){     	
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('X-Requested-With', 'XMLHttpRequest');
		this.headers.append('Authorization', JSON.parse(this.cookie['api/auth_token']))

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});

		return this.http.get(environment.serverURL+"api/auth/savenot",options).pipe(
		map((res:Response) => res.json()));
		
    }

    
}