import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Http, Response, Headers,RequestOptions} from '@angular/http';
import { DomSanitizer, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { CookieManagerService } from '../cookie.service';

@Injectable()
export class PrediosService {

	private headers = new Headers();
	constructor(private http: Http,
		private cookie:CookieManagerService){

    }

    getPredios(numero_manzana){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('X-Requested-With', 'XMLHttpRequest');
		this.headers.append('Authorization', JSON.parse(this.cookie['api_token']));

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'GET'
		});
		return this.http.get(environment.serverURL+"api/auth/manzana/all/"+numero_manzana ,options).pipe(
		map((res:Response) => res.json()));
    }

     getFotosPredio(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('X-Requested-With', 'XMLHttpRequest');
		this.headers.append('Authorization', JSON.parse(this.cookie['api_token']));

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		return this.http.post(environment.serverURL+"api/auth/get/fotos",data ,options).pipe(
		map((res:Response) => res.json()));
    }

    validaPredio(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('X-Requested-With', 'XMLHttpRequest');
		this.headers.append('Authorization', JSON.parse(this.cookie['api_token']));

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		return this.http.post(environment.serverURL+"api/auth/valida/predio",data ,options).pipe(
		map((res:Response) => res.json()));
    }


    activaPredio(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('X-Requested-With', 'XMLHttpRequest');
		this.headers.append('Authorization', JSON.parse(this.cookie['api_token']));

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		return this.http.post(environment.serverURL+"api/auth/act/predio",data ,options).pipe(
		map((res:Response) => res.json()));
    }


    reportePredios(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('X-Requested-With', 'XMLHttpRequest');
		this.headers.append('Authorization', JSON.parse(this.cookie['api_token']));

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		return this.http.post(environment.serverURL+"api/auth/reporte/predios",data ,options).pipe(
		map((res:Response) => res.json()));
    }


    eliminarPredio(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('X-Requested-With', 'XMLHttpRequest');
		this.headers.append('Authorization', JSON.parse(this.cookie['api_token']));

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		return this.http.post(environment.serverURL+"api/auth/delete/predio",data ,options).pipe(
		map((res:Response) => res.json()));
    }

    updatePredio(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('X-Requested-With', 'XMLHttpRequest');
		this.headers.append('Authorization', JSON.parse(this.cookie['api_token']));

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		return this.http.post(environment.serverURL+"api/auth/update/predio",data ,options).pipe(
		map((res:Response) => res.json()));
    }


    getTipoVias(){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('X-Requested-With', 'XMLHttpRequest');
		this.headers.append('Authorization', JSON.parse(this.cookie['api_token']));
		
		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'GET'
		});

		return this.http.get(environment.serverURL+"api/auth/get/tipovias" ,options).pipe(
		map((res:Response) => res.json()));
    }


}
