
import { Component, OnInit, ViewEncapsulation,Inject } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {MatDialog, MatSort,MatDialogConfig} from '@angular/material';

@Component({
  selector: 'modal_confirmar',
  templateUrl: './modal_confirmar.component.html',
  styleUrls: ['./modal_confirmar.component.scss']
})

export class ModalConfirmarComponent implements OnInit {

  menus =[];
  menuId;

  submenus =[];
  submenuId;

	constructor(public dialogRef: MatDialogRef<ModalConfirmarComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, 
              public dialog: MatDialog) { 
    
  }
        
	ngOnInit() {
    
  }

  onNoClick(){
    this.data.result = 0;
    this.dialogRef.close(0);
  }
  onSiClick(){
    this.data.result = 1;
    this.dialogRef.close(1);
  }

}



