import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Http, Response, Headers,RequestOptions} from '@angular/http';
import { DomSanitizer, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
//import { NotificacionesElement } from '../pages/services/notificaciones/NotificacionesElement';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CostosServices {
	private headers = new Headers();
	constructor(private http: Http){

    }

    //////////////////// Load Page //////////////////
     getMontos(){
    	this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'GET'
		});

		return this.http.get(environment.serverURL+"api/get/montonoti",options).pipe(
		map((res:Response) => res.json()));    	
    }

     getMotivo(){
    	this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'GET'
		});

		return this.http.get(environment.serverURL+"api/motivo/notificacioness",options).pipe(
		map((res:Response) => res.json()));    	
    }

    
    getDocumento(){
    	this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'GET'
		});

		return this.http.get(environment.serverURL+"api/get/doc/noti",options).pipe(
		map((res:Response) => res.json()));    	
    }
    /////////////////// Fin Load Page ///////////////
   
   //buscarUltimo
   

   /////////////////// INICIO MONTOS /////////////////
   	 saveMonto(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		return this.http.post(environment.serverURL+"api/save/montnoti" ,data,options).pipe(
		map((res:Response) => res.json()));
    }

    updateMonto(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		return this.http.post(environment.serverURL+"api/update/montonoti" ,data,options).pipe(
		map((res:Response) => res.json()));
    }

    getHistorialMontos(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		return this.http.post(environment.serverURL+"api/get/historialmontos" ,data,options).pipe(
		map((res:Response) => res.json()));
    }

    buscarUltimo(data){
    	this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		return this.http.post(environment.serverURL+"api/buscar/ultimo" ,data,options).pipe(
		map((res:Response) => res.json()));
    }

   ///////////////////  FIN MONTOS //////////////////

   //////////////////  INICIO FECHAS////////////////
   actFechaFin(data){
    	this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		
		return this.http.post(environment.serverURL+"api/buscar/fechfn" ,data,options).pipe(
		map((res:Response) => res.json()));
    }
 

    actFechaIn(data){
    	this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		
		return this.http.post(environment.serverURL+"api/buscar/fechin" ,data,options).pipe(
		map((res:Response) => res.json()));
    }
   /////////////////   FIN FECHAS /////////////////
}