import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Http, Response, Headers,RequestOptions} from '@angular/http';
import { DomSanitizer, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
//import { IncidenciasElement } from '../../pages/services/Buscar/IncidenciasElement';

@Injectable()
export class LibroReclamosService {

	private headers = new Headers();
	constructor(private http: Http){

    }

	getTipoDocumentos(){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'GET'
		});

		return this.http.get(environment.serverURL+"api/tipo-documentos",options).pipe(
		map((res:Response) => res.json()));
    } 

    getSedes(){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'GET'
		});

		return this.http.get(environment.serverURL+"api/sedes",options).pipe(
		map((res:Response) => res.json()));
    }

    getMenus(){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'GET'
		});

		return this.http.get(environment.serverURL+"api/menu-libro",options).pipe(
		map((res:Response) => res.json()));
    }

    save(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		return this.http.post(environment.serverURL+"api/incidencia" ,data,options).pipe(
		map((res:Response) => res.json()));
    }

     getIncidencia(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		return this.http.post(environment.serverURL+"api/get/incidencia" ,data,options).pipe(
		map((res:Response) => res.json()));
    }

    getIncidencias(){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'GET'
		});
		return this.http.get(environment.serverURL+"api/get/incidencias" ,options).pipe(
		map((res:Response) => res.json()));
    }

    getEstado(){
    	this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'GET'
		});

		return this.http.get(environment.serverURL+"api/get/incidencias/estado",options).pipe(
		map((res:Response) => res.json()));
    }

     getEtapas(){
    	this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'GET'
		});

		return this.http.get(environment.serverURL+"api/get/incidencias/etapas",options).pipe(
		map((res:Response) => res.json()));
    }

    getResultado(){
    	this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'GET'
		});

		return this.http.get(environment.serverURL+"api/get/incidencias/resultado",options).pipe(
		map((res:Response) => res.json()));
    }

    updateIncidencia(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		return this.http.post(environment.serverURL+"api/update/incidencia" ,data,options).pipe(
		map((res:Response) => res.json()));
    }

    
     enviarContribuyente(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		return this.http.post(environment.serverURL+"api/enviarMail" ,data,options).pipe(
		map((res:Response) => res.json()));
    }


}
