import { Component, OnInit, Input, HostListener, ElementRef } from '@angular/core';

import { FunctionService } from '../../lib/services/function.service';
import { LoginService } from "../../lib/services/login.service";
import { CookieManagerService } from "../../lib/services/cookie.service";
import { Router } from '@angular/router';

@Component({
  selector: 'cdk-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
	isOpen: boolean = false;

  	//currentUser = null;
  	Hari;
  	

  	@Input() currentUser = null;
  	@HostListener('document:click', ['$event', '$event.target'])
  	onClick(event: MouseEvent, targetElement: HTMLElement) {
    	if (!targetElement) {
     		return;
    	}

    	const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    	if (!clickedInside) {
      		this.isOpen = false;
    	}
  	}
  	
    
  	constructor(private elementRef: ElementRef,
              public functionService:FunctionService,
              private loginService:LoginService,
              private cookie:CookieManagerService,
              private router: Router) {   }


  	ngOnInit() {
  	}

    logout() {
      this.cookie.removeCookie('api_token');
      this.loginService.logout();
      this.router.navigate(['/login']);
    }

}
