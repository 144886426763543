import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ServicesComponent } from './services/services.component';
import { LoginComponent } from './login/login.component';
//import { ServicesModule } from './services/services.module';

const pagesRoutes: Routes = [
  	//{ path: 'services', component: ServicesComponent ,data: { animation: 'services' }},

  	 { path: 'services', loadChildren: './services/services.module#ServicesModule' },
     

  	//{ path: 'login', component: LoginComponent ,data: { animation: 'login' }}
  	//{ path: 'services/buscar', component: ServicesComponent ,data: { animation: 'services' }},
];

@NgModule({
  imports: [
    RouterModule.forChild(pagesRoutes)
  	],
  exports: [
    RouterModule
  ]
})
export class PagesRouterModule {}