
import { Component, OnInit, ViewEncapsulation,Inject } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {MatDialog, MatSort,MatDialogConfig} from '@angular/material';

@Component({
  selector: 'modal_confirmacion',
  templateUrl: './modal_confirmacion.component.html',
  styleUrls: ['./modal_confirmacion.component.scss']
})

export class ModalConfirmacionComponent implements OnInit {

  menus =[];
  menuId;

  submenus =[];
  submenuId;

	constructor(public dialogRef: MatDialogRef<ModalConfirmacionComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, 
              public dialog: MatDialog) { 
    
  }
        
	ngOnInit() {
    
  }

  onNoClick(){
    this.dialogRef.close();
  }

}



