import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Http, Response, Headers,RequestOptions} from '@angular/http';
import { DomSanitizer, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
//import { NotificacionesElement } from '../pages/services/notificaciones/NotificacionesElement';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PagosServices {
	private headers = new Headers();
	constructor(private http: Http){

    }

    ////////////////////Notificadores///////////////////////////
    getNotificadoresLista(){
    	this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'GET'
		});

		return this.http.get(environment.serverURL+"api/get/notificadores",options).pipe(
		map((res:Response) => res.json()));
    }
    //////////////////// Fin Notificadores///////////////////////////


    /////////////////////// Incicio Pagos ///////////////////////////////////
    getPagosTodos(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		return this.http.post(environment.serverURL+"api/pagos/notificadores/porfecha" ,data,options).pipe(
		map((res:Response) => res.json()));
    }

    getPagoPersonal(data){
    	this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		return this.http.post(environment.serverURL+"api/pagos/notificador" ,data,options).pipe(
		map((res:Response) => res.json()));
    }
    ////////////////////////// Fin pagos
   
   /////////////////////Inicio detalle Reporte Pagos/////////////////////////
    getDetalleNotificadores(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		return this.http.post(environment.serverURL+"api/all/notificadores" ,data,options).pipe(
		map((res:Response) => res.json()));
    }
    /////////////////// Fin detalle Reporte pagos////////////////////////////

   
}
	