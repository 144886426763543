import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Http, Response, Headers,RequestOptions} from '@angular/http';
import { DomSanitizer, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { CookieManagerService } from '../cookie.service';

@Injectable()
export class ModalService {

	private headers = new Headers();
	constructor(private http: Http,
		private cookie:CookieManagerService){

    }       

    registrarFoto(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('X-Requested-With', 'XMLHttpRequest');
		this.headers.append('Authorization', JSON.parse(this.cookie['api_token']));

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		return this.http.post(environment.serverURL+"api/auth/savmodalfoto" ,data,options).pipe(
		map((res:Response) => res.json()));
    }

    CrearModal(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('X-Requested-With', 'XMLHttpRequest');
		this.headers.append('Authorization', JSON.parse(this.cookie['api_token']));

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		return this.http.post(environment.serverURL+"api/auth/sav/modal" ,data,options).pipe(
		map((res:Response) => res.json()));
    }

    ActualizarModal(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('X-Requested-With', 'XMLHttpRequest');
		this.headers.append('Authorization', JSON.parse(this.cookie['api_token']));

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		return this.http.post(environment.serverURL+"api/auth/act/modal" ,data,options).pipe(
		map((res:Response) => res.json()));
    }

    getModal(){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('X-Requested-With', 'XMLHttpRequest');
		this.headers.append('Authorization', JSON.parse(this.cookie['api_token']));
		
		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'GET'
		});		

		return this.http.get(environment.serverURL+"api/auth/get/modal",options).pipe(
		map((res:Response) => res.json()));
    } 

	

	

    

}