import { Component, OnInit, Input } from '@angular/core';
import { menus } from './menu-element';
import {MenuService} from '../../lib/services/webadministracion/menus.service';
import { CookieManagerService } from '../../lib/services/cookie.service';

@Component({
  selector: 'cdk-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {

    @Input() iconOnly:boolean = false;
    public menus ;//= menus;

    public menussss = [];

    constructor(private menuService:MenuService,
    	private cookie:CookieManagerService) { }

    ngOnInit() {
    	var dataEn = {
	      "user_id":this.cookie['user_id']
	    }	
    	this.menuService.userMenus(dataEn).subscribe((data) => {  
	       //console.log(data.data); 
	       for(var i= 0; i<data.data.length; i++){
	       	var bandera = false;
	       		if(this.menussss.length == 0){
	       			this.menussss.push(data.data[i].submenu.menu);
	       		}else{
	       			for(var j= 0; j<this.menussss.length; j++){
	       				if(this.menussss[j].id == data.data[i].submenu.menu.id){
	       					bandera = true;
	       				}
	       			}

	       			if(!bandera){
	       				
	       				this.menussss.push(data.data[i].submenu.menu);
	       			}
	       		}
	       }
	       for(var j= 0; j<this.menussss.length; j++){
					this.menussss[j].sub = [];
			}
	       for(var i= 0; i<data.data.length; i++){
	       		
       			for(var j= 0; j<this.menussss.length; j++){
       				if(this.menussss[j].id == data.data[i].submenu.menu.id){
       					this.menussss[j].sub.push(data.data[i].submenu);
       				}
       			}
	       }

	       
	       this.menussss.sort(function (a, b) {
		  		if (a.orden > b.orden) {
		   		 return 1;
		  		}
		  		if (a.orden < b.orden) {
		   		 return -1;
		  		}
		 		 // a must be equal to b
		  		return 0;
				});

	       this.menus = this.menussss;

	       //console.log(this.menus);
	    },
	    (err) => {
	      console.log(err)
	    });
    }

}
