import { NgModule } from '@angular/core';
import { 
        MatCardModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatInputModule,
        MatToolbarModule,
        MatIconModule,
        MatCheckboxModule,
        MatListModule,
       } from '@angular/material';
import { MatChipsModule } from '@angular/material/chips';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PagesRouterModule } from './pages.routes';
/*import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';*/
//import { ServicesComponent } from './services/services.component';
import { CoreModule } from '../core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http'


@NgModule({
    imports: [
        MatCardModule,
        CommonModule,
        FlexLayoutModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatInputModule,
        MatToolbarModule,
        MatIconModule,
        MatCheckboxModule,
        MatListModule,
        MatChipsModule,
        CoreModule,
        HttpClientModule,
        HttpModule,
        PagesRouterModule ],
        declarations: [ 
     
            //LoginComponent  
        /*ContactComponent,
        AboutComponent,*/
        //ServicesComponent
    ],
    exports: [
    ],
    providers: [
        
    ]
})
export class PagesModule {
}
