
import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


/*Inicio componentes*/
//import { BuscarComponent } from './pages/services/buscar/buscar.component';
//import { ContestarComponent } from './pages/services/contestar/contestar.component';

/*Inicio componentes*/

const routes: Routes = [
    
   /* {
      path: 'pages/services/buscar', component: BuscarComponent,
    },
     {
      path: 'pages/services/contestar', component: ContestarComponent,
    },*/

    ];

    @NgModule({
  imports: [ RouterModule.forRoot(routes, { useHash: true }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}