import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LazyLoadModule } from './lazy-load/lazy-load.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { PagesModule } from './pages/pages.module';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
//import { LoaderComponent } from './global/loader';

import { LibroReclamosService} from './lib/services/libro-reclamos.service';
import { CostosServices} from './lib/services/notificadores/costos.notificaciones.services';
import { NotificacionesService} from './lib/services/notificadores/notificaciones.services';
import { PagosServices} from './lib/services/notificadores/pago.notificadores.services';
import { CookieManagerService } from './lib/services/cookie.service';
import { FunctionService  } from './lib/services/function.service';
import { ServerService  } from './lib/services/server.service';
import { CookieService  } from 'ng2-cookies';
import { LoginService} from './lib/services/login.service';
import { LoaderService} from './lib/services/loader.services';
import { AuthGuard } from './_guards/auth.guard';

import { ManzanasService} from './lib/services/appfotopredio/manzanas.service';
import { PrediosService} from './lib/services/appfotopredio/predios.service';

import { SliderServices} from './lib/services/web/imagenes.service';
import { NotasServices} from './lib/services/web/notas.services';
import { wbFotoServices} from './lib/services/web/wbFotos.Services';
import { ModalService} from './lib/services/web/modal.service';


import { AppRoutingModule } from './routing.module';
import { MatIconModule} from '@angular/material';

//====================INICIO ADMINISTRACION =================
import { PerfilService } from './lib/services/webadministracion/perfil.service';
import { SitesAccessService } from './lib/services/webadministracion/sitesaccess.service';
import { MenuService } from './lib/services/webadministracion/menus.service';
import { SubMenuService } from './lib/services/webadministracion/submenu.service';
import { PerfilSubMenuService } from './lib/services/webadministracion/perfilsubmenu.service';
import { UsuariosService } from './lib/services/webadministracion/usuarios.service';
import { UserPerfilService } from './lib/services/webadministracion/userperfil.service';
import { UserSiteAccessService } from './lib/services/webadministracion/usersitesaccess.service';

import { ExcelService } from './lib/services/excel/excel.service';
//====================FIN ADMINISTRACION =================

import { ModalConfirmacionComponent } from './lib/component/modal_confirmacion/modal_confirmacion.component';
import { ModalConfirmarComponent } from './lib/component/modal_confirmar/modal_confirmar.component';

@NgModule({
    entryComponents: [
       ModalConfirmacionComponent,
       ModalConfirmarComponent
    ],
  declarations: [
    AppComponent,
    ModalConfirmacionComponent,
    ModalConfirmarComponent,
   // LoaderComponent
    //BuscarComponent,
    //ContestarComponent
    
  ],
  imports: [
    BrowserModule,
    LazyLoadModule,
    CoreModule,
    BrowserAnimationsModule,
    PagesModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    MatIconModule,
   
    //ServicesModule
  ],
  providers: [
      LibroReclamosService,
      CostosServices,
      CookieManagerService,
      FunctionService,
      ServerService,
      CookieService,
      LoginService,
      ManzanasService,
      PerfilService,
      SitesAccessService,
      MenuService,
      SubMenuService,
      PerfilSubMenuService,
      UsuariosService,
      UserPerfilService,
      UserSiteAccessService,
      PrediosService,
      SliderServices,
      NotasServices, 
      NotificacionesService,
      PagosServices,
      ExcelService,
     { provide: LoginService, useClass:LoginService},
     { provide: CookieManagerService, useClass:CookieManagerService},
     { provide: LibroReclamosService, useClass:LibroReclamosService},
     { provide: CostosServices, useClass:CostosServices},
     { provide: NotificacionesService, useClass:NotificacionesService},
     { provide: PagosServices, useClass:PagosServices},
     { provide: ModalService, useClass:ModalService},

     { provide: ServerService, useClass:ServerService},
     { provide: LoaderService, useClass:LoaderService},
     { provide: PrediosService, useClass:PrediosService},
     { provide: SliderServices, useClass:SliderServices},
     { provide: NotasServices, useClass:NotasServices},
     { provide: wbFotoServices, useClass:wbFotoServices},
     { provide: AuthGuard, useClass:AuthGuard}
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
