import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Http, Response, Headers,RequestOptions} from '@angular/http';
import { DomSanitizer, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
//import { IncidenciasElement } from '../../pages/services/Buscar/IncidenciasElement';
import { CookieManagerService } from './cookie.service';
import { FunctionService } from './function.service';
import { LoaderService } from './loader.services';
import {finalize} from 'rxjs/operators';

@Injectable()
export class LoginService {

	private headers = new Headers();
	constructor(private http: Http,	
				private cookie:CookieManagerService,
				private functionService:FunctionService,
				private loaderService:LoaderService){

    }

     private showLoader(id): void {
      this.loaderService.show(id);
  	}

  	private onEnd(id): void {
      this.loaderService.hide(id);
  	}

    
    getToken(user){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('X-Requested-With', 'XMLHttpRequest');

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});

		this.showLoader({"id":1, "service":"LoginService", "function":"getToken"});

		return this.http.post(environment.serverURL+"api/auth/login" ,user,options).pipe(
		map((res:Response) => res.json()),finalize(()=>this.onEnd(1)),);
    }

    infoUser(){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('X-Requested-With', 'XMLHttpRequest');
		this.headers.append('Authorization', JSON.parse(this.cookie['api_token']));
		
		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'GET'
		});

		this.showLoader({"id":3, "service":"LoginService", "function":"infoUser"});
		return this.http.get(environment.serverURL+"api/auth/user" ,options).pipe(
		map((res:Response) => res.json()),finalize(()=>this.onEnd(3)),);
    }

    logout(){
    	this.cookie.removeCookie('id_user');

    	localStorage.removeItem("currentUser");
	    localStorage.removeItem("tipo_login");
	    this.functionService.setUserName("");
        this.functionService.setEmailUser(""); 

		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('X-Requested-With', 'XMLHttpRequest');
		this.headers.append('Authorization', JSON.parse(this.cookie['api_token']));
		
		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'GET'
		});

		
		return this.http.get(environment.serverURL+"api/auth/logout" ,options).pipe(
		map((res:Response) => res.json()));
    }


}
