import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Http, Response, Headers,RequestOptions} from '@angular/http';
import { DomSanitizer, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
//import { NotificacionesElement } from '../pages/services/notificaciones/NotificacionesElement';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {
	private headers = new Headers();
	constructor(private http: Http){

    }
   

    mostrarLote(data){
    	
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'GET'
		 
		});
		/*revisar*/
		return this.http.post(environment.serverURL+"api/get/lote" ,data,options).pipe(
		map((res:Response) => res.json()));
    }   
   

     mostrarLoteporFecha(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'GET'
		 
		});
		return this.http.post(environment.serverURL+"api/get/lotfech" ,data,options).pipe(
		map((res:Response) => res.json()));
    }

		  	
     mostrarLotfecha(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		return this.http.post(environment.serverURL+"api/get/lote/fecha" ,data,options).pipe(
		map((res:Response) => res.json()));
    }

   
    

    //////////////////////// OBTEENER NOTIFICACIONES ////////////////////////////////
    getNotificacionesAsignadas(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		/*revisar*/
		return this.http.post(environment.serverURL+"api/get/asig" ,data,options).pipe(
		map((res:Response) => res.json()));
    }

    getNotificacionesNoAsignadas(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		/***************revisar*////////
		return this.http.post(environment.serverURL+"api/get/noasig" ,data,options).pipe(
		map((res:Response) => res.json()));
    }

    ////////////////////// FIN OBTEENER NOTIFICACIONES ////////////////////////////////

    //////////////////// INICIO //////////////////////////
 	 gelLoteGeneral(data){
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');

		const options = new RequestOptions(
		{
		  headers: this.headers,
		  method:'POST'
		});
		/***************revisar*////////
		return this.http.post(environment.serverURL+"api/get/gen" ,data,options).pipe(
		map((res:Response) => res.json()));
    }
    //////////////////// FIN  //////////////////////////


    

	
}
