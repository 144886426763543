import { Injectable } from '@angular/core';
//import { CookieService } from 'ngx-cookie';
import { CookieService} from 'ng2-cookies';
import * as _ from "lodash";

@Injectable() 
export class CookieManagerService  {
	private api_token: string = '';
    private id_user: string = '';
    private user_id: string = '';
    private data_new_predio:any = null;

	cookies: Object;
    keys: Array<string>;

    constructor(private cookieService:CookieService) {
        this.updateCookie();
        this.initCookie();
    }

    // Update Cookie
    updateCookie() {
        this.cookies = this.cookieService.getAll();
        //console.log(this.cookies);
        this.keys = Object.keys(this.cookies);
    }

    // Add Cookie
    addCookie(cName: string, cValue: string) {
        this.cookieService.set(cName, cValue);
        this.updateCookie();
        this.initCookie();
    }

    // Remove Cookie
    removeCookie(name: string) {
        this.cookieService.delete(name);
        this.updateCookie();
        this.initCookie();
    }

    initCookie(){
    	// Token
        if(this.cookies['api_token'] != undefined){
            this.api_token = this.cookies['api_token'];
        }
        // Data para nuevo predio (tipo documento, numero de documento)
        if(this.cookies['data_new_predio'] != undefined){
            this.data_new_predio = this.cookies['data_new_predio'];
        }
        // Token
        if(this.cookies['id_user'] != undefined){
            this.id_user = this.cookies['id_user'];
        }
        // Token
        if(this.cookies['user_id'] != undefined){
            this.user_id = this.cookies['user_id'];
        }
    }

}